<template>
    <div class="container" data-v-35cf7caa="">
        <div class="row" data-v-35cf7caa="">
            <div class="col-12" data-v-35cf7caa="">
                <div class="sign__content" style="min-height: 100vh;">
                    <form class="sign__trailer card" >
                        <router-link to="/" class="sign__logo" style="margin-bottom: 10px;">
                            <img src="../../assets/zain_gray.png" style="margin-top: 10px;">
                        </router-link><div class="sign__group" >
                        <div class="row" >
                            <div class="col-12 col-xl-12" data-v-35cf7caa="" v-for="post in contentData" :key="post.id">
                                <div class="article__content" data-v-35cf7caa="">
                                    <p data-v-35cf7caa="">{{ post.title_ar }}</p>
                                </div>
                                <!-- <video controls="" class="plyr" controlslist="nodownload" style="margin-right: 0px;width: 100%;">
                                    <source :src="post.trailer" type="Video/webm" size="720" >
                                </video> -->
                                <img :src="post.carsoul_img" style="border-radius: 15px;width:100%"/>
                                <div class="article__content" data-v-35cf7caa="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <center data-v-35cf7caa="">
                            <div class="sign__group pointer" style="justify-content: center;" data-v-35cf7caa="">
                                <span class="article__content" style="color: #fff; align-content: flex-end; font-family: &quot;Cairo&quot;, sans-serif;" data-v-35cf7caa="">لمشاهدة الحلقة كاملة الرجائ الضغط علي اشتراك</span>
                                <a @click.prevent="DSPLinkSub()" style="width: 200px;" class="sign__btn pointer header-btn" >اشتراك </a>
                            </div>
                        </center>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { HTTPDSP } from '@/Api/http-dsp';
import { useCookie } from 'vue-cookie-next'
import { ref } from '@vue/reactivity';
import { onMounted, watchEffect } from '@vue/runtime-core';
export default {
   setup() {
        const cookie = useCookie()
        const contentData = ref([]);
        const getTrailer = async () => {
            await HTTP.get(`ContentById.php?CID=`+cookie.getCookie("content_id")).then(res => {
                contentData.value = res.data.Content;
            })
        };
        onMounted(() => {
            getTrailer();
        });
        watchEffect(() => {
            if (cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
                HTTPDSP.post(`DSPCheckLogin.php?msisdn=`+cookie.getCookie("msisdn")).then((res) => {
                    if (res.data.status == 1 ) {
                        return this.$router.go(-1);
                    } else {
                        cookie.removeCookie("msisdn");
                        cookie.removeCookie("Content_id");
                        cookie.removeCookie("status");
                    }
                });
            }
        }); 
        const DSPLinkSub = () => {
            // cookie.setCookie("landing_content",post.id);
            window.open("https://dsplp.sd.zain.com/?p=9619984619", "_blank");	
        }
        return {
           contentData, 
           DSPLinkSub     
        };
    }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>